import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { H1 } from "../../styles/typography";

const formatCurrency = (value) =>
  value.toLocaleString("pt-PT", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "EUR",
    currencyDisplay: "symbol",
    style: "currency",
  });

const OrderProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrderProductsSubContainer = styled.div``;

const ProductsTitle = styled(H1)`
  color: #3a4648;
  text-align: center;
`;

const ProductImage = styled(GatsbyImage)`
  display: none;
  filter: drop-shadow(10px 10px 10px #000);
  height: 120px;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const ProductImageContainer = styled.div`
  display: none;
  flex: 0 250px;
  justify-content: center;
  visibility: none;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #3a4648;
  margin-bottom: 7px;
`;

const SharedInputStyled = css`
  font-size: 15px;
  background-color: #ffffff;
  border: none;
  height: 50px;
  padding: 10px;
  width: 100%;
  color: #3a4648;
  box-sizing: border-box;
  border: 1px solid #3a4648;
  outline: none;
  border-radius: 5px;
  ::placeholder {
    color: #3a4648;
  }
`;

const StyledInput = styled.input`
  ${SharedInputStyled}
  max-width: 100px;
  height: 50px;
  margin-left: 20px;

  @media screen and (min-width: 768px) {
    max-width: 200px;
  }
`;

const ProductRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const ProductTextContainer = styled.div`
  text-align: left;

  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }
`;

const Description = styled.p`
  text-align: left;
  font-size: 14px;
`;

const Cost = styled(Label)`
  margin: 0 0 0 20px;
  font-size: 18px;
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #3a4648;
  margin-bottom: 30px;
`;

const OrderProducts = (props) => {
  const [orderProducts, setOrderProducts] = useState({});

  const onQuantityChange = (e, product) => {
    const parsedValue = parseInt(e.target.value, 10);
    const productName = product.frontmatter.title.toLowerCase();

    if (!Number.isNaN(parsedValue) && parsedValue >= 0) {
      setOrderProducts({
        ...orderProducts,
        [productName]: {
          quantity: parsedValue,
          price: product.frontmatter.price,
        },
      });
    } else {
      setOrderProducts({
        ...orderProducts,
        [productName]: { quantity: 0, price: 0 },
      });
    }
  };

  let total = 0;
  Object.keys(orderProducts).forEach((product) => {
    total += orderProducts[product].quantity * orderProducts[product].price;
  });

  return (
    <>
      <ProductsTitle>Produtos</ProductsTitle>
      <OrderProductsContainer>
        <OrderProductsSubContainer>
          {props.products.map((product) => (
            <ProductRow key={product.id}>
              <ProductImageContainer>
                <ProductImage
                  image={
                    product.frontmatter.thumbnail.childImageSharp
                      .gatsbyImageData
                  }
                  alt={product.frontmatter.title}
                />
              </ProductImageContainer>
              <ProductTextContainer>
                <Label htmlFor={product.id}>{product.frontmatter.title}</Label>
                <Description>{product.frontmatter.size}</Description>
              </ProductTextContainer>
              <StyledInput
                type="number"
                id={product.id}
                name={product.frontmatter.title.toLowerCase()}
                min="0"
                defaultValue="0"
                required
                onChange={(e) => onQuantityChange(e, product)}
              />
              <Cost>{formatCurrency(product.frontmatter.price)}</Cost>
            </ProductRow>
          ))}

          <TotalContainer>Total: {formatCurrency(total)}</TotalContainer>
        </OrderProductsSubContainer>
      </OrderProductsContainer>
    </>
  );
};

export default OrderProducts;
