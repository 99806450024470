import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import MainLayout from "../components/layout/main-layout";
import { Header, HeaderImage } from "../components/layout/header";
import BackgroundImage from "../images/fundo-produtos.jpg";
import Order from "../components/order/Order";

const EncomendasPage = () => {
  const data = useStaticQuery(graphql`
    query EncomendasQuery {
      allMarkdownRemark(
        sort: { order: ASC, fields: frontmatter___order }
        filter: { frontmatter: { layout: { eq: "produto" } } }
      ) {
        nodes {
          id
          html
          frontmatter {
            title
            description
            thumbnail {
              childImageSharp {
                gatsbyImageData(quality: 100, height: 250, placeholder: BLURRED)
              }
            }
            price
            size
            visible
          }
        }
      }
    }
  `);

  const products = data.allMarkdownRemark.nodes.filter(
    (product) => product.frontmatter.visible
  );

  return (
    <Layout>
      <SEO
        title="Encomendas"
        keywords={["encomendas"]}
        image={BackgroundImage}
      />

      <HeaderImage image={BackgroundImage}>
        <MainLayout size="60vh">
          <Header
            title="Encomende aqui os seus produtos"
            subtitle="Encomendas"
          />
        </MainLayout>
      </HeaderImage>
      <Order products={products} />
    </Layout>
  );
};

export default EncomendasPage;
